import axios from 'axios';
import { getAuth } from '../plugins/ortutils';
export default {
	role: null,
	right: null,
	funcNext: null,
	/**
	 * Ha a right és/vagy role prop-ok üresek, akkor betöltjük
	 *
	 * @param {object} to
	 * @param {function} next
	 * @param {string} prop
	 */
	async loadRightAndRoles(to, next, prop) {
		const auth = getAuth();
		const apiUrl = process.env.NODE_ENV !== 'production' ? process.env.VUE_APP_DEVSERVER : process.env.VUE_APP_PRODUCTSERVER;
		let self = this;
		await axios.get( apiUrl + '/api/users/getUserRightAndRoles?token=' + auth.token)
		.then((response) => {
			self.role = response.data.roles;
			self.right = response.data.right;
			if (self.right == 'researcher') self.checkValidRole(to, next, prop);
			else self.funcNext();
		});
	},
	/**
	 * Ez a függvény dönti el véglegesen, hogy tovább engedjen-e vagy sem
	 *
	 * @param {object} to
	 * @param {function} next
	 */
	checkHasAdmin(to, next) {
		if (this.right != 'admin') next({
			path: '/403',
			params: { nextUrl: to.fullPath }
		});
		else next();
	},
	/**
	 * Ez a függvény dönti el véglegesen, hogy a role-k rendben vannak-e vagy sem
	 *
	 * @param {object} to
	 * @param {function} next
	 * @param {string} prop
	 */
	checkValidRole(to, next, prop) {
		let hasRole = false;
		for(let key in this.role) {
			const val = this.role[key];
			if (key == prop) {
				hasRole = val;
				break;
			}
		}
		if (!hasRole) {
			next({
				path: '/403',
				params: { nextUrl: to.fullPath }
			});
		}
		else next();
	},
	/**
	 * Ezt a függvényt hívjuk meg a Routerben, ami a jogosultságot vizsgálja
	 *
	 * @param {object} to
	 * @param {function} next
	 */
	checkRight(to, next) {
		if (!this.right) this.loadRightAndRoles();
		else this.checkHasAdmin(to, next);
	},
	/**
	 * Ezt a függvényt hívjuk meg a Routerben, ami a szerepköröket vizsgálja
	 *
	 * @param {object} to
	 * @param {function} next
	 * @param {string} prop
	 */
	checkRole(to, next, prop) {
		if (!this.right) this.loadRightAndRoles(to, next, prop);
		else if (this.right != 'admin') this.checkValidRole(to, next, prop);
		else next();
	}
}
