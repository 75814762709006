import Crypto from 'crypto-js';

let code = String.fromCharCode(78, 72, 103, 116, 33, 114, 53, 52, 84, 122);
let authKey = null;
let userRole = null;
let userRight = null;
let rightAdmin = false;
let projectRoleID = null;
let rightResponder = false;
let rightResearcher = false;

/**
 * A rendszeren belül egy üzenet küldésének általános használata
 *
 * @param {object} self
 * @param {string} textMessage
 * @param {string} color
 * @param {int} dismissSecs
 */
let setInfoBar = (self, textMessage, color, dismissSecs = 3, loading = false) => {
    const data = {
        show: true,
        text: textMessage,
        countDown: dismissSecs,
        colorAlert: color,
        loading: loading,
    }
    self.$eventBus.$emit( 'eventSetInfoBarDatas', data );
}

let getStorage = (key, secured = false) => {
    if (!secured) return window.localStorage.getItem(key);
	else {
		const value = window.localStorage.getItem(key);
		return value ? Crypto.AES.decrypt(value, code).toString(Crypto.enc.Utf8) : null;
	}
}

let setRightProp = (right) => {
	switch (right) {
		case 'admin':
			rightAdmin = true;
			rightResearcher = rightResponder = false;
		break;
		case 'researcher':
			rightResearcher = true;
			rightAdmin = rightResponder = false;
		break;
		case 'user':
			rightResponder = true;
			rightResearcher = rightAdmin = false;
		break;
		default:
		break;
	}
}

const setStorage = (key, value, secured = false) => {
    if (!secured) window.localStorage.setItem(key, value);
	else {
		const encrypted = Crypto.AES.encrypt(value, code).toString();
		window.localStorage.setItem(key, encrypted);
	}
}

const removeFromStorage = (key) => {
    window.localStorage.removeItem(key);
}

export const getAuth = () => {
	return getStorage(authKey, true) ? JSON.parse(getStorage(authKey, true)) : null;
}

export let setRight = (right) => {
	userRight = right;
	setRightProp(right);
}

export let setRoles = (roles) => {
	userRole = roles;
}

export let setProjectRoleID = (roleID) => {
	projectRoleID = roleID;
}

export const logoutFromORT = (self) => {
	let that = self;
	self.$api.post('logout')
	.then(() => {
		clearInterval( that.$store.state.loggedInterval );
		that.$store.commit('set', ['loggedInterval', null]);
		that.$store.commit('set', ['isAuthenticated', false]);
		that.$removeFromStorage(self.$authKey);
		that.$router.push({ path: '/login' });
	}).catch((error) => {
		console.error(error);
	});
}

export default {
	install(Vue) {
		Vue.mixin({
			created() {
				authKey = this.$authKey;
				Vue.prototype.$getAuth = getAuth;
				Vue.prototype.$setRight = setRight;
				Vue.prototype.$setRoles = setRoles;
				Vue.prototype.$userRole = userRole;
				Vue.prototype.$userRight = userRight;
				Vue.prototype.$rightAdmin = rightAdmin;
				Vue.prototype.$projectRoleID = projectRoleID;
				Vue.prototype.$rightResponder = rightResponder;
				Vue.prototype.$rightResearcher = rightResearcher;
				Vue.prototype.$setProjectRoleID = setProjectRoleID;
				Vue.prototype.$getStorage = getStorage;
				Vue.prototype.$setStorage = setStorage;
				Vue.prototype.$setInfoBar = setInfoBar;
				Vue.prototype.$logoutFromORT = logoutFromORT;
				Vue.prototype.$removeFromStorage = removeFromStorage;
			}
		});
	}
}
