<template>
	<div>
		<notifications class="notify-position"/>
		<router-view></router-view>
	</div>
</template>

<script>
export default {
	name: 'App',
}
</script>

<style lang="scss">
	// Import Main styles for this application
	@import 'assets/scss/style';
	/** Később fogjuk használni */
	// @import 'vue2-animate/src/sass/vue2-animate';
	.notify-position {
		margin-top: 5.5rem;
	}
</style>
