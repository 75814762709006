import axios from 'axios';
import { getAuth } from './ortutils';
export default {
	install(Vue) {
		Vue.mixin({
			created() {
				const apiUrl = process.env.NODE_ENV !== 'production' ? process.env.VUE_APP_DEVSERVER : process.env.VUE_APP_PRODUCTSERVER;
				const axiosInstance = axios.create({
					baseURL: apiUrl + '/api/'
				});
				axiosInstance.interceptors.request.use((config) => {
					const auth = getAuth();
					if (auth) {
						let params = new URLSearchParams();
						params.append('token', auth.token);
						config.params = params;
					}
					return config;
				});
				Vue.prototype.$api = axiosInstance;
			}
		})
	}
}
