import 'core-js/stable';
import Vue from 'vue';
import CoreuiVue from '@coreui/vue';
import Notifications from 'vue-notification';
import FlowChart from "flowchart-vue";
import Api from './plugins/api';
import App from './App';
import Utils from './plugins/ortutils';
import ScreenSize from './plugins/screensize';
import router from './router/index';
import { iconsSet as icons } from './assets/icons/icons.js';
import store from './store';
import vuexI18n from 'vuex-i18n';
// - hamarosan: import Echo from 'laravel-echo';
import Locales from './locale/octopus-locales.js';
import * as VueGoogleMaps from 'vue2-google-maps';

Vue.prototype.$apiAddress = process.env.NODE_ENV !== 'production' ? process.env.VUE_APP_DEVSERVER : process.env.VUE_APP_PRODUCTSERVER;
Vue.prototype.$frontServerAddress = process.env.NODE_ENV !== 'production' ? process.env.VUE_APP_LOCAL_FRONTSERVER : process.env.VUE_APP_PRODUCT_FRONTSERVER;
Vue.prototype.$adminVersion = '0.6.8.2';
Vue.prototype.$authKey = 'pKju76Thg';
Vue.prototype.$eventBus = new Vue();
Vue.config.performance = true;

/**
 * Ezen kell még alakítani úgy, h tudjon a ws-hez csatlakozni a vue, mert most
 * CORS hibával megáll a dolog
window.Pusher = require('pusher-js');
window.Echo = new Echo({
	broadcaster: 'pusher',
	key: 'local',
	cluster: 'mt1',
	wsHost: window.location.hostname,
	wsPort: 6001,
	wssPort: 6001,
	authEndpoint: 'localhost:8000/broadcasting/auth',
	forceTLS: false,
	enabledTransports: ['ws', 'wss'],
	disableStats: true,
});
window.Echo.private(`App.Models.Projects.2`)
.listen('.ProjectsUpdated', (e) => {
	console.log('socket event =',e)
});
*/

Vue.use(Utils);
Vue.use(Api);
Vue.use(ScreenSize);
Vue.use(CoreuiVue);
Vue.use(FlowChart);
Vue.use(Notifications);
Vue.use(vuexI18n.plugin, store, {
	moduleName: 'i18n',
	onTranslationNotFound (locale, key) {
		console.warn(`i18n :: Key '${key}' not found for locale '${locale}'`);
	}
});
Vue.i18n.add('en', Locales.en);
Vue.i18n.add('hu', Locales.hu);
// SAGE-ből kiszedve: const locale = store.state.currentLang ?? 'en';
const locale = 'en';
Vue.i18n.set(locale);

Vue.use(VueGoogleMaps, {
	load: {
		key: process.env.VUE_APP_MAP_API_CODE,
		libraries: ['places', 'visualization'],
		installComponents: true
		// v: 'OPTIONAL VERSION NUMBER',
	}
});

try {
	new Vue({
		el: '#app',
		store,
		router,
		//CIcon component documentation: https://coreui.io/vue/docs/components/icon
		icons,
		template: '<App/>',
		components: {
			App
		}
	});
}
catch(e) {
	console.error(e);
}
